import React, { useContext, useState } from "react"
import api from "../../utils/api"
import { toast } from "react-toastify"
import { CartContext } from "../../contexts/Cart"
import * as styles from "./index.module.scss"
import Loading from "../../components/Loading"
import { StoreContext } from "../../contexts/Store"
import InputMask from "react-input-mask"
import { navigate } from "gatsby-link"
import redirectToPayHere from "../../utils/redirectToPayhere"
import isEmail from "validator/lib/isEmail"
import { format, parseISO } from "date-fns"

const isDev = value => (process.env.NODE_ENV === "development" ? value : "")

const payment_methods = ["Cash on Delivery", "Online Payment"]

const defaultUserState = {
  first_name: isDev("Mahesh"),
  last_name: isDev("Samudra"),
  email: isDev("maheshsamudra@gmail.com"),
  address: isDev("No. 123, Temple Road"),
  phone: isDev("0713300873"),
  city: isDev("1"),
  payment_method: isDev(payment_methods[0]),
  alternate_option: "",
}

const alternateOptions = [
  "Add higher quantities from the available products",
  "Add substitute products",
  "Only send the available items and refund for the items that are not available",
]

const CheckOutForm = ({
  setShipping = () => null,
  setDeliveryDate = () => null,
  deliveryDate = "",
  total = 0,
}) => {
  const [customer, setCustomer] = useState(defaultUserState)

  const [progress, setProgress] = useState(false)

  const {
    cart: { items },
    actions: { clearCart },
  } = useContext(CartContext)

  const {
    store: { deliveryCities },
  } = useContext(StoreContext)

  const setValue = (name, value) => {
    setCustomer({
      ...customer,
      [name]: value,
    })
    if (name === "city") {
      const city = deliveryCities.find(city => city.id === Number(value))
      if (city) {
        setShipping(Number(city.delivery_charge))
        setDeliveryDate(city.delivery_day)
      } else {
        setShipping(0)
        setDeliveryDate("")
      }
    }
  }

  const submit = async e => {
    e.preventDefault()
    if (
      !customer.first_name ||
      !customer.last_name ||
      !customer.email ||
      !customer.address ||
      !customer.phone ||
      !customer.city ||
      !customer.alternate_option
    ) {
      toast.warning(`All fields are required.`)
      return
    }

    if (!isEmail(customer.email)) {
      toast.warning(`Please enter a valid email address`)
      return
    }

    if (customer.phone.length < 10) {
      toast.warning(`Please enter a valid phone number`)
      return
    }
    setProgress(true)

    // console.log("sending")
    //
    // console.log(customer, items)

    await api("web-orders/new", {
      customer,
      items,
    })
      .then(async res => {
        // console.log(res)
        const { status, order, hash } = res
        if (status === "error") {
          toast.warning(`Failed to place the order. Please retry.`)
          return
        }
        if (order && order.id && order.payment_method === payment_methods[1]) {
          // take the user to online payment page
          redirectToPayHere(order, hash)
          setProgress(false)
          return
        }
        navigate("/cart/success")
        if (process.env.NODE_ENV === "development") return
        clearCart()
        await gtag_report_conversion(window.location.href)

        navigate("/cart/success")
      })
      .catch(error => {
        toast.warning(`Failed to place the order. Please retry.`)
        console.log(error)
      })
    setProgress(false)
  }

  const gtag_report_conversion = async url => {
    if (!window.gtag) return
    const callback = function () {
      if (typeof url != "undefined") {
        window.location = url
      }
    }
    window.gtag("event", "conversion", {
      send_to: "AW-958632364/xD5tCPT74ZIDEKyjjskD",
      value: total,
      currency: "LKR",
      transaction_id: "",
      event_callback: callback,
    })
    return false
  }

  return (
    <div className={"mt-5 mt-lg-0 sticky-top"}>
      <h5>Contact Information</h5>
      <div className="row">
        <div className="col-sm-6">
          <Input
            name={"firstName"}
            label="First name *"
            value={customer.first_name}
            setValue={value => setValue("first_name", value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            name={"lastName"}
            label="Last name *"
            value={customer.last_name}
            setValue={value => setValue("last_name", value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            name={"email"}
            label="Email *"
            value={customer.email}
            setValue={value => setValue("email", value)}
          />
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="phone">Phone number (mobile)</label>
            <InputMask
              id={"phone"}
              className="form-control"
              value={customer.phone}
              mask="079 999 9999"
              maskChar=""
              placeholder={"07x xxx xxxx"}
              onChange={e => setValue("phone", e.target.value)}
            />
          </div>
        </div>
      </div>

      <h5 className="mt-3">Delivery Information</h5>
      <div className="row">
        <div className="col-sm-12">
          <Input
            name={"address"}
            label="Address"
            value={customer.address}
            setValue={value => setValue("address", value)}
          />
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="city">City</label>
            <select
              name="city"
              id="city"
              className="form-control"
              value={customer.city}
              onChange={e => setValue("city", e.target.value)}
              onBlur={e => setValue("city", e.target.value)}
            >
              <option value="" disabled>
                Please select the city
              </option>
              {deliveryCities
                .filter(c => c.store_id !== 3)
                .map(city => (
                  <option value={city.id} key={city.id}>
                    {city.name}
                  </option>
                ))}
            </select>
            <small>
              Please select the nearest city for the delivery fee. If the
              address is farther than 3 km from the selected city, the delivery
              charge may increase.
            </small>
          </div>
        </div>
      </div>

      <h5 className="mt-3">Payment Information</h5>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            {payment_methods.map((method, idx) => (
              <div className="custom-control custom-radio" key={idx}>
                <input
                  type="radio"
                  id={`method_${idx}`}
                  name="payment_method"
                  className="custom-control-input"
                  checked={customer.payment_method === method}
                  onChange={() => setValue("payment_method", method)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`method_${idx}`}
                >
                  {method}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="row">
        {deliveryDate && (
          <div className="col-sm-12">
            <p className={"text-theme"}>
              <small>
                <strong>
                  Your order will be delivered by{" "}
                  {format(parseISO(deliveryDate), "do MMM, yyyy")}
                </strong>
              </small>
            </p>
          </div>
        )}

        <div className="col-sm-12">
          <hr />
          <div className="form-group">
            <label htmlFor="city">
              If a product is out of stock when packing
            </label>
            <select
              name="alternate_option"
              id="alternate_option"
              className="form-control"
              value={customer.alternate_option}
              onChange={e => setValue("alternate_option", e.target.value)}
              onBlur={e => setValue("alternate_option", e.target.value)}
            >
              <option value="" disabled>
                Please select an option
              </option>
              {alternateOptions.map(option => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-sm-12 text-right">
          <button
            className={`btn btn-primary ml-auto ${styles.submit_button}`}
            disabled={progress}
            onClick={submit}
          >
            {progress ? <Loading isLight /> : "Place order"}
          </button>
        </div>
      </div>
    </div>
  )
}

const Input = ({
  name = "",
  label = "",
  value = "",
  setValue = () => null,
}) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
        type="text"
        className="form-control"
        id={name}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </div>
  )
}
export default CheckOutForm
