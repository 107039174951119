import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"
import * as styles from "./index.module.scss"
import { CartContext } from "../../contexts/Cart"
import { StoreContext } from "../../contexts/Store"
import Breadcrumbs from "../../components/Breadcrumbs"
import { GrTrash } from "react-icons/gr"
import currencyFormat from "../../utils/currencyFormat"
import { toast } from "react-toastify"

import CheckoutForm from "./_CheckoutForm"
import Layout from "../../Layout"
import DefaultImage from "../../images/default-store-image.jpg"
import { navigate } from "gatsby-link"
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai"
import isOnlineStoreOpen from "../../config/isOnlineStoreOpen"

const min = 1
const max = 50

const CartPage = () => {
  const {
    cart: { items },
  } = useContext(CartContext)

  return (
    <Layout>
      <Seo title="Cart" noIndex />

      <Breadcrumbs title={"Cart"} />

      {items.length === 0 ? <CartEmpty /> : <Cart />}
    </Layout>
  )
}

const Cart = () => {
  useEffect(() => {
    if (!isOnlineStoreOpen) {
      navigate("/")
    }
  }, [])

  const {
    cart: { items },
    actions: { removeFromCart, clearCart, addToCart },
  } = useContext(CartContext)

  const {
    store: { products },
  } = useContext(StoreContext)

  const deleteItem = (id, name) => {
    removeFromCart(id)
    toast.warning(`${name} was removed from your cart.`)
  }

  const [subtotal, setSubtotal] = useState(0)
  const [shipping, setShipping] = useState(0)
  const [deliveryDate, setDeliveryDate] = useState("")
  const [total, setTotal] = useState(0)

  useEffect(() => {
    if (!items || items.length === 0 || products.length === 0) {
      setSubtotal(0)
      setTotal(shipping)
      return
    }
    let st = 0
    for (let i = 0; i < items.length; i++) {
      const cartItem = items[i]

      const product = products.find(product => product.id === cartItem.id)
      // const product = items[i];
      if (!product) {
        clearCart()
        toast.warning(
          `Your cart contained products that are no longer available. Please select your items again.`,
        )
        navigate(`/organic-store`)
        return
      }
      st += Number(product.selling_price) * cartItem.amount
    }
    setSubtotal(st)
    setTotal(st + shipping)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, products, shipping, items.length])

  if (products.length === 0) {
    return (
      <div className="container mt-5 pt-4">
        <p className="text-center">Loading...</p>
      </div>
    )
  }

  return (
    <div className="container mt-5 pt-4">
      <div className="row">
        <div className="col-md-6">
          <div className="sticky-top">
            <h5>Your cart</h5>
            <table className={`table ${styles.cart_list}`}>
              <thead className={"mt-3 position-sticky"}>
                <tr>
                  <th>Product</th>
                  <th className={"text-center"}>Qty</th>
                  <th className={"text-right"}>Price</th>
                </tr>
              </thead>
              <tbody>
                {products &&
                  items &&
                  items.length > 0 &&
                  items.map((item, idx) => {
                    const product = products.find(
                      product => product.id === item.id,
                    )
                    if (!product) return null

                    return (
                      <tr key={idx}>
                        <td>
                          <img
                            src={product.image || DefaultImage}
                            alt={product.name}
                          />
                          <p>
                            <span>{product.name}</span>
                            <span>{currencyFormat(product.selling_price)}</span>
                          </p>
                        </td>
                        <td>
                          <div>
                            <button
                              className={`btn ${styles.minus}`}
                              onClick={() =>
                                addToCart({
                                  id: item.id,
                                  amount: item.amount > 1 ? item.amount - 1 : 1,
                                })
                              }
                              disabled={item.amount <= min}
                            >
                              <AiOutlineMinus />
                            </button>
                            <span>{item.amount}</span>
                            <button
                              className={`btn ${styles.plus}`}
                              onClick={() =>
                                addToCart({
                                  id: item.id,
                                  amount: item.amount + 1,
                                })
                              }
                              disabled={item.amount >= max}
                            >
                              <AiOutlinePlus />
                            </button>
                          </div>
                        </td>
                        <td>
                          <span>
                            {currencyFormat(
                              item.amount * product.selling_price,
                            )}
                          </span>
                          <button
                            className={"btn btn-default btn-sm"}
                            onClick={() => deleteItem(product.id, product.name)}
                          >
                            <GrTrash />
                          </button>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>

            <Link to={"/organic-store"} className={"btn btn-light mt-3"}>
              Add more items
            </Link>

            <ul className={styles.cart_summary}>
              <li>
                <span>Subtotal</span>
                <span>{currencyFormat(subtotal)}</span>
              </li>
              <li>
                <span>Shipping</span>
                <span>{currencyFormat(shipping)}</span>
              </li>
              <li>
                <span>Total</span>
                <span>{currencyFormat(total)}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-xl-5 offset-xl-1">
          <CheckoutForm
            setShipping={setShipping}
            setDeliveryDate={setDeliveryDate}
            deliveryDate={deliveryDate}
            total={total}
          />
        </div>
      </div>
    </div>
  )
}

const CartEmpty = () => {
  return (
    <div className={"container text-center mt-5 pt-5"}>
      <h5>Your cart is empty</h5>
      <p>Please go to our Organic Store and fill your basket.</p>
      <Link to={"/organic-store"} className={"btn btn-primary"}>
        Go to Organic Store
      </Link>
    </div>
  )
}
export default CartPage
