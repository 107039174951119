import wrapInApiUrl from "./wrapInApiUrl"
import wrapInWebUrl from "./wrapInWebUrl"

const redirectToPayHere = (order, hash) => {
  const params = {
    merchant_id: process.env.GATSBY_PAYHERE_MERCHANT_ID,
    return_url: wrapInWebUrl("cart/success"),
    cancel_url: wrapInWebUrl("cart/canceled"),
    notify_url: wrapInApiUrl("online-payment-callback"),
    first_name: order.first_name,
    last_name: order.last_name,
    email: order.email,
    phone: order.phone,
    address: order.address,
    city: order.city,
    country: "Sri Lanka",
    order_id: order.id,
    items: "Purchase from Owita - #" + order.id,
    currency: "LKR",
    amount: order.total / 100,
    hash,
  }

  let form = document.createElement("form")
  document.body.appendChild(form)
  form.method = "post"
  form.action = process.env.GATSBY_PAYHERE_ENDPOINT

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      let input = document.createElement("input")
      input.type = "hidden"
      input.name = key
      input.value = params[key]
      form.appendChild(input)
    }
  }
  form.submit()
}

export default redirectToPayHere
